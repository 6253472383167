import {ApiRequest, GetVerb, PostVerb, orderContactCompaniesUrl, PutVerb, DeleteVerb} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {OrderContactCompanyRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getOrderContactCompaniesAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      OrderRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      ExcludeBlobs,
      IncludeDataSourceFields,
      IncludeFieldSettings,
      IncludeFieldNameRegistry,
      IncludeNameFieldRegistry,
      IncludeGridColumns,
      IncludeGroupColumns,
      IncludeDebugData,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        OrderRootId = Number(OrderRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs || false;
        IncludeDataSourceFields = IncludeDataSourceFields || true;
        IncludeFieldSettings = IncludeFieldSettings || true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry || true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry || true;
        IncludeGridColumns = IncludeGridColumns || true;
        IncludeGroupColumns = IncludeGroupColumns || true;
        IncludeDebugData = IncludeDebugData || false;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${orderContactCompaniesUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&orderrootid=${OrderRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: OrderContactCompanyRecordType, Data });
      }, "orderContactCompanies - getOrderContactCompaniesAdvancedSearchData");
    },
    async getOrderContactCompanies(context, {
      OrderRootId,
      UseCache
    }) {
      return timeOperation(async () => {
        OrderRootId = Number(OrderRootId ?? 0);
        UseCache = UseCache ?? false;
        if (OrderRootId > 0) {
          const formattedUrl = `${orderContactCompaniesUrl}?orderrootid=${OrderRootId}&includemetadata=true`;
          return await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        } else {
          console.error("OrderRootId is not valid");
        }
        return [];
      }, "orders - getOrderContactCompanies");
    },
    async addOrderContactCompany(context, orderContactCompany) {
      return timeOperation(async () => {
        return await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: orderContactCompaniesUrl, Payload: orderContactCompany});
      }, "orderContactCompanies - addOrderContactCompany");
    },
    async updateOrderContactCompany(context, orderContactCompany) {
      return timeOperation(async () => {
        const formattedUrl = `${orderContactCompaniesUrl}/${Number(orderContactCompany?.RootId ?? 0)}`;
        return await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: orderContactCompany});
      }, "orderContactCompanies - updateOrderContactCompany");
    },
    async removeOrderContactCompany(context, orderContactCompany) {
      await timeOperation(async () => {
        const formattedUrl = `${orderContactCompaniesUrl}/${orderContactCompany.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "orderContactCompanies - removeOrderContactCompany");
    },
  },
};
