import {ApiRequest, GetVerb, PostVerb, notificationsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {MessagesRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {
    notifications: [],
    fields: [
      { name: "Id", dataType: "Number" },
      { name: "OrderRootId", dataType: "Number" },
      { name: "ProductRootId", dataType: "Number" },
      { name: "OrganizationRootId", dataType: "Number" },
      { name: "PlaceOrderId", dataType: "Number" },
      { name: "DocumentPlaceOrderRootId", dataType: "Number" },
      { name: "CreatedByUserRootId", dataType: "Number" },
      { name: "EventType", dataType: "String" },
      { name: "SoftProOrderNumber", dataType: "String" },
      { name: "Subject", dataType: "String" },
      { name: "Message", dataType: "String" },
      { name: "SendToAddresses", dataType: "String" },
      { name: "ReplyToAddresses", dataType: "String" },
      { name: "ProductName", dataType: "String" },
      { name: "ProductDisplayName", dataType: "String" },
      { name: "OrganizationName", dataType: "String" },
      { name: "OrganizationDisplayName", dataType: "String" },
      { name: "UserEmail", dataType: "String" },
      { name: "UserDisplayName", dataType: "String" },
      { name: "HasAttachments", dataType: "Boolean" },
      { name: "Processed", dataType: "Boolean" },
      { name: "CreatedDateTime", dataType: "DateTime" },
      { name: "ProcessedDateTime", dataType: "DateTime" },
      { name: "NotificationAttachments", dataType: "Array" },
      { name: "LinksQueuedAttachmentRootIds", dataType: "String" },
    ],
  },
  getters: {},
  mutations: {
    setNotificationDataSimple(state, notificationsData) {
      state.notifications.splice(0, state.notifications.length);
      let notifications = [];
      notificationsData?.forEach((notification) => {
        let notificationCopy = {};
        state.fields.forEach((field) => {
          switch (field.dataType) {
            case "Number":
              if (notification.hasOwnProperty(field.name) && String(notification[field.name] || "").length > 0) {
                notificationCopy[field.name] = Number(notification[field.name]);
              } else {
                notificationCopy[field.name] = null;
              }
              break;
            case "Boolean":
              if (notification.hasOwnProperty(field.name) && String(notification[field.name] || "").length > 0) {
                notificationCopy[field.name] = Boolean(notification[field.name]);
              } else {
                notificationCopy[field.name] = null;
              }
              break;
            case "String":
              if (notification.hasOwnProperty(field.name) && String(notification[field.name] || "").length > 0) {
                notificationCopy[field.name] = String(notification[field.name]);
              } else {
                notificationCopy[field.name] = "";
              }
              break;
            case "DateTime":
              if (notification.hasOwnProperty(field.name) && String(notification[field.name] || "").length > 0) {
                if (notification[field.name] && String(notification[field.name]).includes("T") && !String(notification[field.name]).includes("Z")) {
                  notification[field.name] = `${notification[field.name]}.000Z`;
                }
                notificationCopy[field.name] = new Date(notification[field.name]);
              } else {
                notificationCopy[field.name] = null;
              }
              break;
            case "Array":
              if (notification.hasOwnProperty(field.name) && String(notification[field.name] || "").length > 0) {
                notificationCopy[field.name] = _.cloneDeep(notification[field.name]) || [];
              } else {
                notificationCopy[field.name] = [];
              }
              break;
          }
        });
        notificationCopy.HasAttachmentsString = notificationCopy.HasAttachments ? String.fromCharCode(0x2713) : "";
        notifications.push(notificationCopy);
      });
      state.notifications = notifications?.sort((no1, no2) => (no1.CreatedDateTime > no2.CreatedDateTime ? 1 : -1)) || [];
    },
  },
  actions: {
    async getNotificationsData(context, { UserRootId, OrganizationRootId, OrderNumber, PreviewParameters, IncludeMetadata, CanUseServerCache }) {
      await timeOperation(async () => {
        UserRootId = Number(UserRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        OrderNumber = String(OrderNumber || "");
        IncludeMetadata = IncludeMetadata || true;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${notificationsUrl
          }?userrootid=${UserRootId
          }&organizationrootid=${OrganizationRootId
          }&ordernumber=${OrderNumber
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata}&canusecache=${CanUseServerCache}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setNotificationDataSimple", data);
      }, "notifications - getNotificationsData");
    },
    async getNotificationsAdvancedSearchData(context, {
      ProductRootId,
      OrganizationRootId,
      UserRootId,
      SearchRootId,
      PreviewParameters,
      IncludeMetadata,
      ExcludeBlobs,
      IncludeDataSourceFields,
      IncludeFieldSettings,
      IncludeFieldNameRegistry,
      IncludeNameFieldRegistry,
      IncludeGridColumns,
      IncludeGroupColumns,
      IncludeDebugData,
      CanUseServerCache,
    }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        SearchRootId = Number(SearchRootId || 0);
        IncludeMetadata = IncludeMetadata || true;
        ExcludeBlobs = ExcludeBlobs || false;
        IncludeDataSourceFields = IncludeDataSourceFields || true;
        IncludeFieldSettings = IncludeFieldSettings || true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry || true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry || true;
        IncludeGridColumns = IncludeGridColumns || true;
        IncludeGroupColumns = IncludeGroupColumns || true;
        IncludeDebugData = IncludeDebugData || false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${notificationsUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null }));
        context.commit("setSearchResultsData", { ViewType: MessagesRecordType, Data });
      }, "notifications - getNotificationsAdvancedSearchData");
    },
    async addNotification(context, notification) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: notificationsUrl, Payload: notification });
        context.commit("_addNotification", data);
      }, "notifications - addNotification");
    },
  },
};
