import {ApiRequest, GetVerb, userConfigSettingsLogUrl} from "@/lib/api";
import timeOperation from "@/utils/timeOperation";
import getLocalTimezone from "@/utils/getLocalTimezone";
import {UserConfigSettingsLogRecordType} from "@/lib/settings";
import {getGridData} from "@/store/searchResults";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getUserConfigSettingsLogAdvancedSearchData(
      context,
      {
        ProductRootId,
        OrganizationRootId,
        UserRootId,
        SearchRootId,
        IncludeMetadata,
        ExcludeBlobs,
        IncludeDataSourceFields,
        IncludeFieldSettings,
        IncludeFieldNameRegistry,
        IncludeNameFieldRegistry,
        IncludeGridColumns,
        IncludeGroupColumns,
        IncludeDebugData,
        PreviewParameters,
        CanUseServerCache,
      }
    ) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId ?? 0);
        OrganizationRootId = Number(OrganizationRootId ?? 0);
        UserRootId = Number(UserRootId ?? 0);
        SearchRootId = Number(SearchRootId ?? 0);
        IncludeMetadata = IncludeMetadata ?? true;
        ExcludeBlobs = ExcludeBlobs || false;
        IncludeDataSourceFields = IncludeDataSourceFields || true;
        IncludeFieldSettings = IncludeFieldSettings || true;
        IncludeFieldNameRegistry = IncludeFieldNameRegistry || true;
        IncludeNameFieldRegistry = IncludeNameFieldRegistry || true;
        IncludeGridColumns = IncludeGridColumns || true;
        IncludeGroupColumns = IncludeGroupColumns || true;
        IncludeDebugData = IncludeDebugData || false;
        CanUseServerCache = CanUseServerCache || true;
        let previewParametersJson = PreviewParameters?.length > 0 ? JSON.stringify(PreviewParameters) : "";
        const formattedUrl = `${userConfigSettingsLogUrl
          }?productrootid=${ProductRootId
          }&organizationrootid=${OrganizationRootId
          }&userrootid=${UserRootId
          }&searchrootid=${SearchRootId
          }&previewparameters=${previewParametersJson
          }&timezone=${encodeURIComponent(getLocalTimezone())
          }&includemetadata=${IncludeMetadata
          }&excludeblobs=${ExcludeBlobs
          }&includedatasourcefields=${IncludeDataSourceFields
          }&includefieldsettings=${IncludeFieldSettings
          }&includefieldnameregistry=${IncludeFieldNameRegistry
          }&includenamefieldregistry=${IncludeNameFieldRegistry
          }&includegridcolumns=${IncludeGridColumns
          }&includegroupcolumns=${IncludeGroupColumns
          }&includedebugdata=${IncludeDebugData
          }&canusecache=${CanUseServerCache}`;
        const Data = getGridData(await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null}));
        context.commit("setSearchResultsData", { ViewType: UserConfigSettingsLogRecordType, Data });
        return Data;
      }, "userConfigSettingsLog - getUserConfigSettingsLogAdvancedSearchData");
    },
  },
};
