import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, formLayoutsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

import { FORM_LAYOUT_CONTROLS_KEY, FORM_LAYOUT_GROUPS_ITEMS, FORM_LAYOUT_SETTINGS_JSON_KEY, FORM_LAYOUT_STYLES_JSON_KEY } from "@/types/formLayoutTypes.js";
import {
  FORM_LAYOUT_CONTROLS_HEIGHT_KEY,
  FORM_LAYOUT_CONTROLS_WIDTH_KEY,
  FORM_LAYOUT_CONTROLS_X_POS,
  FORM_LAYOUT_CONTROLS_Y_POS,
  FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY,
  FORM_LAYOUT_CONTROLS_TEXT_LABEL_KEY,
  FORM_LAYOUT_CONTROLS_DEFAULT_VALUE,
  FORM_LAYOUT_CONTROLS_SETTINGS_JSON_KEY,
  FORM_LAYOUT_CONTROLS_STYLES_JSON_KEY,
} from "@/types/formLayoutControlsTypes.js";
import {
  TEMPLATE_BUILDER_TYPE_KEY,
  TEMPLATE_BUILDER_HEIGHT_KEY,
  TEMPLATE_BUILDER_WIDTH_KEY,
  TEMPLATE_BUILDER_X_POS,
  TEMPLATE_BUILDER_Y_POS,
} from "@/components/templateBuilder/templateBuilderTypes.js";
import { TEXT } from "@/utils/types/inputTypes.js";

function formatFormLayoutToSend(formLayout) {
  const result = {
    ...formLayout,
    [FORM_LAYOUT_SETTINGS_JSON_KEY]: JSON.stringify(formLayout[FORM_LAYOUT_SETTINGS_JSON_KEY] || ""),
    [FORM_LAYOUT_GROUPS_ITEMS]: formLayout[FORM_LAYOUT_GROUPS_ITEMS] || [],
    [FORM_LAYOUT_STYLES_JSON_KEY]: JSON.stringify(formLayout?.[FORM_LAYOUT_STYLES_JSON_KEY] || {}),
    [FORM_LAYOUT_CONTROLS_KEY]:
      formLayout?.[FORM_LAYOUT_CONTROLS_KEY]?.map?.((controlItem) => {
        const additionalInfo = {};
        if (controlItem[FORM_LAYOUT_CONTROLS_DEFAULT_VALUE] && controlItem[FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY] === TEXT) {
          additionalInfo[FORM_LAYOUT_CONTROLS_TEXT_LABEL_KEY] = controlItem[FORM_LAYOUT_CONTROLS_DEFAULT_VALUE];
        }
        return {
          ..._.omit(controlItem, [TEMPLATE_BUILDER_HEIGHT_KEY, TEMPLATE_BUILDER_WIDTH_KEY, TEMPLATE_BUILDER_X_POS, TEMPLATE_BUILDER_Y_POS]),
          ...additionalInfo,
          [FORM_LAYOUT_CONTROLS_HEIGHT_KEY]: controlItem[TEMPLATE_BUILDER_HEIGHT_KEY],
          [FORM_LAYOUT_CONTROLS_WIDTH_KEY]: controlItem[TEMPLATE_BUILDER_WIDTH_KEY],
          [FORM_LAYOUT_CONTROLS_X_POS]: controlItem[TEMPLATE_BUILDER_X_POS],
          [FORM_LAYOUT_CONTROLS_Y_POS]: controlItem[TEMPLATE_BUILDER_Y_POS],
          [FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY]: controlItem[TEMPLATE_BUILDER_TYPE_KEY],
          [FORM_LAYOUT_CONTROLS_SETTINGS_JSON_KEY]: JSON.stringify(controlItem[FORM_LAYOUT_SETTINGS_JSON_KEY] || {}),
          [FORM_LAYOUT_CONTROLS_STYLES_JSON_KEY]: JSON.stringify(controlItem[FORM_LAYOUT_STYLES_JSON_KEY] || {}),
        };
      }) || [],
  };
  return result;
}
function formatReceivedFormLayout(formLayout) {
  const rawSettings = formLayout?.[FORM_LAYOUT_SETTINGS_JSON_KEY] || "";
  const formLayoutSettings = rawSettings ? JSON.parse(rawSettings) : {};
  return {
    ...formLayout,
    [FORM_LAYOUT_SETTINGS_JSON_KEY]: formLayoutSettings,
    [FORM_LAYOUT_STYLES_JSON_KEY]: formLayout?.[FORM_LAYOUT_STYLES_JSON_KEY] ? JSON.parse(formLayout?.[FORM_LAYOUT_STYLES_JSON_KEY]) : {},
    [FORM_LAYOUT_CONTROLS_KEY]:
      formLayout?.[FORM_LAYOUT_CONTROLS_KEY]?.map?.((controlItem) => {
        const additionalInfo = {};
        if (controlItem[FORM_LAYOUT_CONTROLS_TEXT_LABEL_KEY] && controlItem[FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY] === TEXT) {
          additionalInfo[FORM_LAYOUT_CONTROLS_DEFAULT_VALUE] = controlItem[FORM_LAYOUT_CONTROLS_TEXT_LABEL_KEY];
        }
        const newControlItem = {
          ..._.omit(controlItem, [FORM_LAYOUT_CONTROLS_HEIGHT_KEY, FORM_LAYOUT_CONTROLS_WIDTH_KEY, FORM_LAYOUT_CONTROLS_X_POS, FORM_LAYOUT_CONTROLS_Y_POS]),
          ...additionalInfo,
          [TEMPLATE_BUILDER_HEIGHT_KEY]: controlItem[FORM_LAYOUT_CONTROLS_HEIGHT_KEY],
          [TEMPLATE_BUILDER_WIDTH_KEY]: controlItem[FORM_LAYOUT_CONTROLS_WIDTH_KEY],
          [TEMPLATE_BUILDER_X_POS]: controlItem[FORM_LAYOUT_CONTROLS_X_POS],
          [TEMPLATE_BUILDER_Y_POS]: controlItem[FORM_LAYOUT_CONTROLS_Y_POS],
          [TEMPLATE_BUILDER_TYPE_KEY]: controlItem[FORM_LAYOUT_CONTROLS_CONTROL_TYPE_KEY],
          [FORM_LAYOUT_SETTINGS_JSON_KEY]: JSON.parse(controlItem[FORM_LAYOUT_CONTROLS_SETTINGS_JSON_KEY]),
          [FORM_LAYOUT_STYLES_JSON_KEY]: JSON.parse(controlItem[FORM_LAYOUT_CONTROLS_STYLES_JSON_KEY]),
        };
        return newControlItem;
      }) || [],
  };
}

export default {
  state: {
    cachedFormLayoutsByLocation: {},
    formLayouts: [],
    userFormLayoutsData: [],
    formLayoutTemplate: null,
    formLayoutToShare: null,
    isFormLayoutSaveDirty: false,
    formLayoutToReload: {},
    deletedFormLayoutRootId: 0,
    cachedFormLayoutTemplateProductRootId: -1,
  },
  getters: {
    getFormLayoutByRootId: (state) => (rootId) => {
      rootId = Number(rootId || 0);
      if (rootId > 0) {
        let formLayout = state.formLayouts.find((formLayout) => Number(formLayout?.RootId || 0) === rootId);
        if (formLayout?.RootId > 0) {
          return _.cloneDeep(formLayout);
        }
        if (state.cachedFormLayoutsByLocation) {
          for (const formLocationRootId in state.cachedFormLayoutsByLocation) {
            if (state.cachedFormLayoutsByLocation.hasOwnProperty(formLocationRootId)) {
              let formLayout = state.cachedFormLayoutsByLocation[formLocationRootId]?.find((formLayout) => Number(formLayout?.RootId || 0) === rootId);
              if (formLayout?.RootId > 0) {
                return _.cloneDeep(formLayout);
              }
            }
          }
        }
      }
      return null;
    },
    getFormLayoutByName: (state) => (name) => {
      name = String(name || "");
      if (name.length > 0) {
        let formLayout = state.formLayouts.find((formLayout) => String(formLayout?.Name || "") === name);
        if (formLayout?.RootId > 0) {
          return _.cloneDeep(formLayout);
        }
        if (state.cachedFormLayoutsByLocation) {
          for (const formLocationRootId in state.cachedFormLayoutsByLocation) {
            if (state.cachedFormLayoutsByLocation.hasOwnProperty(formLocationRootId)) {
              let formLayout = state.cachedFormLayoutsByLocation[formLocationRootId]?.find((formLayout) => String(formLayout?.Name || "") === name);
              if (formLayout?.RootId > 0) {
                return _.cloneDeep(formLayout);
              }
            }
          }
        }
      }
      return null;
    },
  },
  mutations: {
    setUserFormLayoutData(state, formLayoutsData) {
      state.userFormLayoutsData = formLayoutsData || [];
    },
    setFormLayout(state, { RootId, data }) {
      const index = state.formLayouts.findIndex((layout) => layout.RootId === RootId);
      if (index < 0) {
        state.formLayouts.push(data);
      } else {
        state.formLayouts[index] = data;
      }
    },
    setFormLayoutData(state, formLayoutsData) {
      state.formLayouts =
        formLayoutsData?.sort((formLayout1, formLayout2) => (formLayout1.Name > formLayout2.Name ? 1 : -1)).map((formLayout) => formatReceivedFormLayout(formLayout)) || [];
    },
    setFormLayoutOperatorsData(state, formLayoutOperators) {
      state.formLayoutOperators = formLayoutOperators;
    },
    setCachedFormLayoutTemplateProductRootId(state, cachedFormLayoutTemplateProductRootId) {
      state.cachedFormLayoutTemplateProductRootId = Number(cachedFormLayoutTemplateProductRootId || -1);
    },
    clearFormLayouts(state) {
      state.formLayouts = [];
    },
    setFormLayoutTemplateData(state, formLayoutTemplate) {
      state.formLayoutTemplate = formLayoutTemplate || {};
    },
    setDeletedFormLayoutRootId(state, deletedFormLayoutRootId) {
      state.deletedFormLayoutRootId = deletedFormLayoutRootId || 0;
    },
    setFormLayoutToReload(state, formLayoutToReload) {
      state.formLayoutToReload = formLayoutToReload;
    },
    setFormLayoutToShare(state, formLayoutToShare) {
      state.formLayoutToShare = formLayoutToShare;
    },
    clearFormLayoutToReload(state) {
      for (let member in state.formLayoutToReload) {
        delete state.formLayoutToReload[member];
      }
    },
    setIsFormLayoutSaveDirty(state, isFormLayoutSaveDirty) {
      state.isFormLayoutSaveDirty = isFormLayoutSaveDirty;
      state.isFormLayoutSaveDirty = false; // FIXME: This logic is broken causing a dialog to appear repeatedly
    },
    setCachedFormLayoutsByLocation(state, { FormLocationRootId, FormattedData }) {
      if (FormLocationRootId > 0) {
        state.cachedFormLayoutsByLocation[FormLocationRootId] = _.cloneDeep(FormattedData) || [];
      }
    },
    _addFormLayout(state, formLayout) {
      const formLayoutsData = _.cloneDeep(state.formLayouts) || [];
      formLayoutsData.push(formLayout);
      state.formLayouts = formLayoutsData.sort((formLayout1, formLayout2) => (formLayout1.Name > formLayout2.Name ? 1 : -1));
    },
    _deleteFormLayout(state, rootId) {
      const formLayoutsData = _.cloneDeep(state.formLayouts) || [];
      state.formLayouts = formLayoutsData.filter((formLayout) => Number(formLayout?.RootId || 0) !== Number(rootId || 0)) || [];
    },
    _updateFormLayoutSortColumnSettings(state, updateFormLayout) {
      const rootId = Number(updateFormLayout?.RootId || 0);
      if (rootId > 0) {
        let foundFormLayout = state.formLayouts.find((formLayout) => Number(formLayout?.RootId || 0) === Number(rootId || 0)) || null;
        if (foundFormLayout?.RootId > 0) {
          foundFormLayout.SortByColumn = updateFormLayout.SortByColumn || "";
          foundFormLayout.SortDirection = updateFormLayout.SortDirection || "";
        }
      }
    },
  },
  actions: {
    async getProductLevelFormLayouts(context, { ProductRootId, InjectReferences, IncludeDrafts, IncludeMetadata, IncludeExtendedMetadata }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        InjectReferences = InjectReferences || true;
        IncludeDrafts = IncludeDrafts || false;
        IncludeMetadata = IncludeMetadata || true;
        IncludeExtendedMetadata = IncludeExtendedMetadata || false;
        const formattedUrl = `${formLayoutsUrl}?productrootid=${ProductRootId}&organizationrootid=0&userrootid=0&isproductlevel=true&injectreferences=${InjectReferences}&includedrafts=${IncludeDrafts}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&isproductlevel=true&isorganizationlevel=false&isuserlevel=false`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setFormLayoutData", data);
      }, "formLayouts - getProductLevelFormLayouts");
    },
    async getOrganizationLevelFormLayouts(context, { ProductRootId, OrganizationRootId, InjectReferences, IncludeDrafts, IncludeMetadata, IncludeExtendedMetadata }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        InjectReferences = InjectReferences || true;
        IncludeDrafts = IncludeDrafts || false;
        IncludeMetadata = IncludeMetadata || true;
        IncludeExtendedMetadata = IncludeExtendedMetadata || false;
        const formattedUrl = `${formLayoutsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=0&isorganizationlevel=true&injectreferences=${InjectReferences}&includedrafts=${IncludeDrafts}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&isproductlevel=false&isorganizationlevel=true&isuserlevel=false`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setFormLayoutData", data);
      }, "formLayouts - getOrganizationLevelFormLayouts");
    },
    async getUserLevelFormLayouts(
      context,
      { ProductRootId, UserRootId, OrganizationRootId, FormLocationRootId, InjectReferences, IncludeDrafts, IncludeMetadata, IncludeExtendedMetadata }
    ) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        UserRootId = Number(UserRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        FormLocationRootId = Number(FormLocationRootId || 0);
        InjectReferences = InjectReferences || true;
        IncludeDrafts = IncludeDrafts || false;
        IncludeMetadata = IncludeMetadata || true;
        IncludeExtendedMetadata = IncludeExtendedMetadata || false;
        const formattedUrl = `${formLayoutsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&formlocationrootid=${FormLocationRootId}&isuserlevel=true&injectreferences=${InjectReferences}&includedrafts=${IncludeDrafts}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&isproductlevel=false&isorganizationlevel=false&isuserlevel=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setUserFormLayoutData", data);
      }, "formLayouts - getUserLevelFormLayouts");
    },
    async getFormLayout(
      context,
      {
        RootId,
        ProductRootId,
        OrganizationRootId,
        InjectReferencesForOrganizationRootId,
        InjectReferences,
        IncludeDrafts,
        IncludeMetadata,
        IncludeExtendedMetadata,
        IsProductLevel,
        IsOrganizationLevel,
        IsUserLevel,
      }
    ) {
      return timeOperation(async () => {
        RootId = Number(RootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        InjectReferencesForOrganizationRootId = Number(InjectReferencesForOrganizationRootId || 0);
        InjectReferences = InjectReferences || true;
        IncludeDrafts = IncludeDrafts || false;
        IncludeMetadata = IncludeMetadata || true;
        IncludeExtendedMetadata = IncludeExtendedMetadata || false;
        IsProductLevel = IsProductLevel || false;
        IsOrganizationLevel = IsOrganizationLevel || false;
        IsUserLevel = IsUserLevel || false;
        const formattedUrl = `${formLayoutsUrl}/${RootId}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&injectreferencesfororganizationrootid=${InjectReferencesForOrganizationRootId}&injectreferences=${InjectReferences}&includedrafts=${IncludeDrafts}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        let formattedData = {};
        if (data) {
          formattedData = formatReceivedFormLayout(data);
        }
        return formattedData;
      }, "formLayouts - getFormLayout");
    },
    async addFormLayout(context, formLayout) {
      const NoCache = formLayout?.NoCache || false;
      const filteredFormLayout = _.omit(formLayout, ["NoCache"]);
      return timeOperation(async () => {
        const formattedFormLayout = formatFormLayoutToSend(filteredFormLayout);
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formLayoutsUrl, Payload: formattedFormLayout });
        if (data?.RootId > 0 && !NoCache) {
          formattedFormLayout.RootId = data.RootId;
          context.commit("_addFormLayout", _.cloneDeep(data));
        }
        return formatReceivedFormLayout(data);
      }, "formLayouts - addFormLayout");
    },
    async cloneFormLayout(context, { FormLayout, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        IsLogLevel = IsLogLevel || false;
        IsProductLevel = IsProductLevel || false;
        IsOrganizationLevel = IsOrganizationLevel || false;
        IsUserLevel = IsUserLevel || false;
        const formattedFormLayout = formatFormLayoutToSend(FormLayout);
        const formattedUrl = `${formLayoutsUrl}/${Number(
          FormLayout?.RootId || 0
        )}/clone?isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: formattedFormLayout });
        if (data?.RootId > 0) {
          FormLayout.RootId = data.RootId;
          context.commit("_addFormLayout", data);
        }
        return formatReceivedFormLayout(data);
      }, "formLayouts - cloneFormLayout");
    },
    async exportFormLayout(context, { FormLayoutRootId, ProductRootId, OrganizationRootId, UserRootId, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel }) {
      return timeOperation(async () => {
        FormLayoutRootId = Number(FormLayoutRootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        IsLogLevel = IsLogLevel || false;
        IsProductLevel = IsProductLevel || false;
        IsOrganizationLevel = IsOrganizationLevel || false;
        IsUserLevel = IsUserLevel || false;
        const formattedUrl = `${formLayoutsUrl}/${FormLayoutRootId}/export?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "formLayouts - exportFormLayout");
    },
    async importFormLayout(context, { ProductRootId, OrganizationRootId, UserRootId, IsLogLevel, IsProductLevel, IsOrganizationLevel, IsUserLevel, UpdateExisting, FileContents }) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        UserRootId = Number(UserRootId || 0);
        IsLogLevel = IsLogLevel || false;
        IsProductLevel = IsProductLevel || false;
        IsOrganizationLevel = IsOrganizationLevel || false;
        IsUserLevel = IsUserLevel || false;
        UpdateExisting = UpdateExisting || false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${formLayoutsUrl}/import?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&isproductlevel=${IsProductLevel}&isorganizationlevel=${IsOrganizationLevel}&isuserlevel=${IsUserLevel}&updateexisting=${UpdateExisting}`;
        return await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
      }, "formLayouts - importFormLayout");
    },
    async updateFormLayout(context, formLayout) {
      return timeOperation(async () => {
        const formattedFormLayout = formatFormLayoutToSend(formLayout);
        const formattedUrl = `${formLayoutsUrl}/${Number(formattedFormLayout?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: { ...formattedFormLayout } });
        return formatReceivedFormLayout(_.cloneDeep(data));
      }, "formLayouts - updateFormLayout");
    },
    async deleteFormLayout(context, rootId) {
      await timeOperation(async () => {
        rootId = Number(rootId || 0);
        if (rootId > 0) {
          const formattedUrl = `${formLayoutsUrl}/${Number(rootId || 0)}`;
          await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("_deleteFormLayout", rootId || 0);
        }
      }, "formLayouts - deleteFormLayout");
    },
    async removeFormLayout(context, formLayout) {
      await timeOperation(async () => {
        const formattedUrl = `${formLayoutsUrl}/${Number(formLayout?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDeletedFormLayoutRootId", formLayout.RootId);
        context.commit("clearFormLayouts");
      }, "formLayouts - removeFormLayout");
    },
    async getCachedFormLayoutForUserForLocation(
      context,
      { ProductRootId, FormLocationRootId, UserRootId, InjectReferences, IncludeDrafts, IncludeMetadata, IncludeExtendedMetadata }
    ) {
      FormLocationRootId = Number(FormLocationRootId || 0);
      if (context.state.cachedFormLayoutsByLocation?.hasOwnProperty(`${FormLocationRootId}`)) {
        return context.state.cachedFormLayoutsByLocation[`${FormLocationRootId}`];
      } else {
        return timeOperation(async () => {
          const res = await context.dispatch("getFormLayoutForUserForLocation", {
            ProductRootId,
            FormLocationRootId,
            UserRootId,
            InjectReferences,
            IncludeDrafts,
            IncludeMetadata,
            IncludeExtendedMetadata,
          });
          return res;
        }, "formLayouts - getFormLayoutForUserForLocation");
      }
    },
    async getFormLayoutForUserForLocation(
      context,
      { ProductRootId, OrganizationRootId, UserRootId, FormLocationRootId, InjectReferences, IncludeDrafts, IncludeMetadata, IncludeExtendedMetadata }
    ) {
      ProductRootId = Number(ProductRootId || 0);
      OrganizationRootId = Number(OrganizationRootId || 0);
      UserRootId = Number(UserRootId || 0);
      FormLocationRootId = Number(FormLocationRootId || 0);
      InjectReferences = InjectReferences || true;
      IncludeDrafts = IncludeDrafts || false;
      IncludeMetadata = IncludeMetadata || true;
      IncludeExtendedMetadata = IncludeExtendedMetadata || false;
      return timeOperation(async () => {
        const formattedUrl = `${formLayoutsUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&userrootid=${UserRootId}&formlocationrootid=${FormLocationRootId}&injectreferences=${InjectReferences}&includedrafts=${IncludeDrafts}&includemetadata=${IncludeMetadata}&includeextendedmetadata=${IncludeExtendedMetadata}&isproductlevel=false&isorganizationlevel=false&isuserlevel=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        const FormattedData = data?.map?.((formLayout) => formatReceivedFormLayout(formLayout)) || [];
        context.commit("setCachedFormLayoutsByLocation", { FormLocationRootId, FormattedData });
        return FormattedData;
      }, "formLayouts - getFormLayoutForUserForLocation");
    },
    async getFormLayoutsFromNavigationPanel(context, { UseCache, Tree }) {
      if (Tree.IsGroup) {
        if (Tree?.Children?.length > 0) {
          for (let i = 0; i < Tree.Children.length; i++) {
            await context.dispatch("getFormLayoutsFromNavigationPanel", { UseCache, Tree: Tree.Children[i] });
          }
        }
      } else {
        if (Tree?.RootId > 0) {
          await context.dispatch("getFormLayout", { UseCache, RootId: Tree.RootId });
        }
      }
    },
  },
};
