<template>
  <div v-if="show" @dragenter.prevent @dragover.prevent>
    <div class="fixed w-screen h-screen top-0 left-0" :class="`${onTopOfModal ? 'z-1001' : 'z-1000'} ${fadeBackground ? 'opacity-20 bg-primary' : ''}`"></div>
    <div
      :style="modalContainerStyles"
      class="overflow-y-auto max-h-screen"
      :class="`${isModal ? 'modal-container' : messageBackground} ${onTopOfModal ? 'z-1001' : 'z-1000'} ${fillScreen ? 'max-width-s max-height-s' : ''} ${
        expandToMaxSize ? 'min-width-s min-height-s' : ''
      } ${growWithContent ? 'grow-width' : ''}`"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { computed, nextTick, onMounted, watch, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "BackgroundOverlay",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fadeBackground: {
      type: Boolean,
      default: false,
    },
    modalContainerStyles: {
      type: Object,
      default: () => ({}),
    },
    growWithContent: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
    onTopOfModal: {
      type: Boolean,
      default: false,
    },
    fillScreen: {
      type: Boolean,
      default: false,
    },
    expandToMaxSize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    // State
    const backgroundOverlayIsActive = computed(() => store.state.modals.backgroundOverlayIsActive);
    // Mutations
    const setBackgroundOverlayIsActive = (value) => store.commit("setBackgroundOverlayIsActive", value);

    const currentActiveBackground = ref(false);

    function handleShowChanged(is) {
      if (backgroundOverlayIsActive.value) {
        if (currentActiveBackground.value) {
          if (!is) {
            currentActiveBackground.value = false;
            setBackgroundOverlayIsActive(false);
          }
        }
      } else if (is) {
        currentActiveBackground.value = true;
        setBackgroundOverlayIsActive(true);
      }
    }
    watch(
      () => props.show,
      (is) => {
        handleShowChanged(is);
      }
    );
    onMounted(async () => {
      await nextTick();
      handleShowChanged(props.show);
    });

    const messageBackground = computed(() => {
      return props.showBackground ? "message-container" : "message-container-no-bg";
    });

    onBeforeUnmount(() => {
      if (currentActiveBackground.value) {
        setBackgroundOverlayIsActive(false);
      }
    });
    return {
      setBackgroundOverlayIsActive,

      messageBackground,
    };
  },
};
</script>

<style scoped>
.z-1000 {
  z-index: 1000000;
}
.z-1001 {
  z-index: 1001;
}
.message-container {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: calc(0.5 * 32px);
  color: #34406b;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
}
.message-container-no-bg {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background-color: #fff;
  padding: 8px 16px;
  border-radius: calc(0.5 * 16px); /* less rounding */
  color: #34406b;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 1px 2px rgb(0 0 0 / 23%);
}
.max-width-s {
  max-width: 95vw;
}
.max-height-s {
  max-height: 95vh;
}
.min-width-s {
  min-width: 95vw;
}
.min-height-s {
  min-height: 95vh;
}
.grow-width {
  display: block;
  width: max-content;
  max-width: 95vw;
  box-sizing: border-box;
}
</style>
