import _ from "lodash";

export default {
  state: {
    failedUploads: [],
    uploadedDocuments: [],
    uploadInProgress: false,
  },
  mutations: {
    setUploadInProgress(state, payload) {
      state.uploadInProgress = payload;
    },
    clearUploadedDocuments(state) {
      state.uploadedDocuments = [];
      state.failedUploads = [];
      state.uploadInProgress = false;
    },
    setUploadedDocuments(state, uploadedDocuments) {
      state.uploadedDocuments = _.cloneDeep(uploadedDocuments);
    },
    setFailedUploads(state, failedUploads) {
      state.failedUploads = _.cloneDeep(failedUploads);
    },
  },
};
