export default async function (functionToTime, name, showAsError = false) {
  const start = Date.now();
  const result = await functionToTime();
  const elapsedTime = (Date.now() - start) / 1000;
  if (showAsError) {
    console.error(`${name} - ${elapsedTime} seconds`);
  } else if (elapsedTime > .5) {
    console.log(`${name} - ${elapsedTime} seconds`);
  }
  return result;
}
